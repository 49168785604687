<template>
  <b-card class="card-profile">
    <h3>{{ cohortData.name }}</h3>
    <hr class="mb-1" />

    <div class="price-details">
      <ul class="list-unstyled">
        <li class="price-detail">
          <div class="detail-title">Status</div>
          <div class="detail-amt">
            {{ title(cohortData.status) }}
          </div>
        </li>
        <li class="price-detail">
          <div class="detail-title">Expires At</div>
          <div v-if="cohortData.expiresAt" class="detail-amt discount-amt">
            {{ moment(cohortData.expiresAt).format('hh:mm A L') }}
          </div>
          <div v-else class="detail-amt discount-amt text-danger">Non-Expiring</div>
        </li>
        <li class="price-detail">
          <div class="detail-title">Password Protected</div>
          <div v-if="!cohortData.password" class="detail-amt discount-amt text-danger">No</div>
          <div v-else class="detail-amt discount-amt">
            <span class="text-success">Yes</span>
            &nbsp;
            <b-button @click="copyCohortPassword" variant="primary" size="sm" style="padding: 3px; text-align: center">
              <feather-icon icon="ClipboardIcon"></feather-icon>
            </b-button>
          </div>
        </li>
        <li class="price-detail">
          <div class="detail-title">Total Profiles</div>
          <div class="detail-amt discount-amt">
            {{ cohortData.profiles.length }}
          </div>
        </li>
        <li class="price-detail">
          <div class="detail-title">External Access</div>
          <div class="detail-amt discount-amt">
            <a :href="`https://recruiters.intellectualpoint.com/cohorts/${cohortData.id}?${cohortData.password ? `?password=${cohortData.password}` : ''}`" target="_blank">
              View
            </a>
          </div>
        </li>
      </ul>

      <hr />

      <b-button
        @click="initiateRecruiterDownload"
        variant="info"
        size="sm"
        block
        :disabled="isDownloading"
      >
        <b-spinner v-if="isDownloading" small />

        Recruiter Export
      </b-button>

      <b-button
        :to="{ name: 'apps-placement-cohort-edit', params: { id: cohortData.id } }"
        variant="primary"
        size="sm"
        block
      >
        Edit Cohort
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BSpinner, BCard, BButton, BAvatar, BRow, BCol, BBadge } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useCohortsList from "../cohort-list/useCohortList";
import { title } from "@core/utils/filter";
import usAppConfig from "@core/app-config/useAppConfig";
import moment from "moment";
import store from "@/store";
import fileDownload from "js-file-download";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ref } from "@vue/composition-api";

const { skin } = usAppConfig();

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BSpinner
  },
  props: {
    cohortData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyCohortPassword() {
      console.log(`copying cohort password`);
      this.$copyText(
        `https://recruiters.intellectualpoint.com/cohorts/${this.cohortData.id}?code=${btoa(this.cohortData.password)}`
      )
        .then((a) => {
          console.log(`copied`, a);
        })
        .catch((e) => console.error(e));
    },
  },
  setup(props) {
    const { resolveCohortStatusVariant } = useCohortsList();

    const isDownloading = ref(false);

    const initiateRecruiterDownload = async () => {
      isDownloading.value = true;

      await store
        .dispatch("app-placement/downloadCohortAsRecruiter", {
          cohortId: props.cohortData.id
        })
        .then((response) => {
          const { data, headers } = response;

          // const r = new RegExp(/attachment; filename="(.+)"/)
          // const dispo = r.exec(headers['Content-Disposition'])
          // console.log(headers, dispo);
          // const docName = dispo[1]

          fileDownload(data, headers["X-Filename"] || `(Recruiter Export) ${props.cohortData.name}.zip`);
          isDownloading.value = false;
        })
        .catch((error) => {
          console.log(`Failed to download student document`, error);
          isDownloading.value = false;

          if (error.response) {
            const { response } = error;
            return this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed to download file.",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: response.data.message || "An unknown error has occurred."
              }
            });
          }

          return this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to download file.",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: e.message
            }
          });
        });
    };

    return {
      avatarText,
      resolveCohortStatusVariant,
      title,
      skin,
      moment,
      initiateRecruiterDownload,
      isDownloading
    };
  },
};
</script>

<style scoped lang="scss">
$card-spacer-y: 0.75rem !default;

.card-profile {
  .card-body {
    position: relative;
    padding: 1.5rem 1rem $card-spacer-y 1rem !important;
  }
}

.price-details {
  .price-title {
    font-weight: 600 !important;
    margin-bottom: 0.75rem;
    margin-top: 1.5rem;
  }

  .price-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    .detail-title {
      &.detail-total {
        font-weight: bolder !important;
      }
    }
  }
}
</style>
